import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;


export default function Distributor() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                    <Title level={2} className="sectionTitle text-left d-none-xs">Now the customer stays with you, forever</Title>
                    <Title level={2} className="sectionTitle text-left d-visible-xs">Customers stay with you, forever</Title>
                    <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/distributor-cover.png')} alt="distributor" width='100%' />
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center d-none-xs">One network to provide all financial products</Title>
              <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">One network for all products</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/customer-management.png')} style={{width:'100%'}} className="br12 mb-20" alt=''/>
                    <Title level={4} className="sectionTitle text-left">Customer management</Title>
                    <p>Manage leads, get real time visibility on your cases, engage with customers, track customer portfolio, all digitally.</p>
                </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/recommendation-engine.png')} style={{width:'100%'}} className="br12 mb-20" alt=''/>
                    <Title level={4} className="sectionTitle text-left">Recommendation engine</Title>
                    <p>Find the best products, cross sell and match right supply for your customers</p>
                </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/embed-sol1.png')} style={{width:'100%'}} className="br12 mb-20" alt=''/>
                    <Title level={4} className="sectionTitle text-left">Access Gateway</Title>
                    <p>Reach Banks, NBFCs, Insurers all in one click, without hassle of any physical processes</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Network that delivers all financial products</Title>
              <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className="text-center">
                  <img src={require('../../img/distributor-loop.png')} className="loopImg" alt='distributor-loop'/>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      {/* <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 6}}>
                  <Title level={4} className="">Case Study</Title>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 18}} className='d-flex justify-space-between'>
                  <div className='case_study'>
                    <Title level={5} className="">Project Year</Title>
                    <span>2022</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Bank Type</Title>
                    <span>Digital Greenfield</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Cloud Partner</Title>
                    <span>OpenShift and Google Cloud</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Geography</Title>
                    <span>India</span>
                  </div>
                </Col>
              </Row>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mt-50 align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/dummy.jpg')} style={{width:'100%'}} alt=''/>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <Title level={4} className="sectionTitle text-left">Challenges</Title>
                  <p style={{marginBottom:'50px'}}>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>

                  <Title level={4} className="sectionTitle text-left">The Solution</Title>
                  <p>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout> */}
      <Foot />
    </>
  )
}
