import React, { useLayoutEffect, useState } from 'react';
import { Layout, Typography, Row, Col, Tabs } from 'antd';
import './Home.css';
import Foot from '../Footer/Footer';
import Head from '../Header/Header';
import Slider from '../Slider';
// import SliderDescription from '../SliderDescription';
import { useParallax, Parallax } from 'react-scroll-parallax';
import { Link } from "react-router-dom";
import classnames from "classnames";
import CallToAction from '../CallToAction';
const { Content } = Layout;
const { Title } = Typography;

const images = [0, 1, 2];

export default function Home() {
    // const parallax = useParallax({speed:50});
    // const parallaxf = useParallax({speed:50});
    // const parallaxs = useParallax({speed:45});
    
    const [visibleImagesMap, setVisibleImagesMap] = useState(
        images.reduce((map, image) => {
          map[image] = false;
          return map;
        }, {})
    );
    useLayoutEffect(() => {
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        //console.log('height', viewportHeight);
        const newVisibleImagesMap = images.reduce((map, image) => {
            map[image] = scrollTop >= image * viewportHeight;
            return map;
        }, {});

        setVisibleImagesMap(newVisibleImagesMap);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const renderContent = () => (
        <>
            <Parallax className="h100" translateY={[20, 0]}>
                <Title level={2} className="sectionMTitle">Distributor</Title>   
                <div className='description'>
                    <ul className='pl15'>
                        <li>Provide financial products from 80+ lenders, insurers</li>
                        <li>Better decisioning, higher conversion, more profitability</li>
                        <li>Digitally manage your relationship with the customer</li>
                    </ul>
                </div>   
                <Link className='btn-txt' to='/distributor'>Learn More...</Link> 
            </Parallax> 
            <Parallax className="h100" translateY={[10, 0]}>
                <Title level={2} className="sectionMTitle">Complementor</Title>
                <div className='description'>
                    <ul className='pl15'>
                        <li>Embed Loan gateway along user journey</li>
                        <li>Automate & integrate customer journeys</li>
                        <li>Reach new customers through OI network</li>
                    </ul>
                </div>
                <Link className='btn-txt' to='/complementor'>Learn More...</Link>
            </Parallax>  
            <Parallax className="h100" translateY={[5, 50]}>
                <Title level={2} className="sectionMTitle">Supply</Title> 
                <div className='description'>
                    <ul className='pl15'>
                        <li>Access to alternate data, customized decisioning</li>
                        <li>Enriched customer filtration, higher conversion</li>
                        <li>Deliver contextualized products to all customers</li>
                    </ul>
                </div> 
                <Link className='btn-txt' to='/supply'>Learn More...</Link>     
            </Parallax> 
        </>
    );

    return (
    <>
    <Head />
    <div className="d-flex flex-column align-items-center justify-content-center sliderPnl">
        <Title className='mainTitle'>One Network to Access <Slider /> </Title>
        {/* <div style={{fontSize:'24px'}}><SliderDescription /></div> */}
    </div>
    <Layout className='white parallax d-none-xs'>
        <Content className='d-flex parallax_w'>
            <div className="Parallax_content">{renderContent()}</div>
            <div className='sticky_container'>
                <div className='sticky'>      
                    <div className="frame">
                        {images.map((image) => (
                            <div
                            className={classnames("image", `image_${image}`, {
                                image_visible: visibleImagesMap[image]
                            })}
                            key={image}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Content>
    </Layout>
    <Layout className='white d-visible-xs parallax-mob'>
        <Content>
            <div className="site-layout-content">
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle text-left">Distributor</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/distributor.png')} className="pdImg" alt='distributor'/>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Products from 80+ lenders</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/distributor'>Learn More...</Link>  
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle text-left">Complementor</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/complementor.png')} className="pdImg" alt="complementor"/>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Embed Loan gateway</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/complementor'>Learn More...</Link>  
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle text-left">Supply</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/supply.png')} className="pdImg" alt='supply'/>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Alternate data, customized decisioning</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/supply'>Learn More...</Link>  
                    </Col>
                </Row>
            </div>
        </Content>
    </Layout>
    <Layout className='grey products'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center mb-80">Products</Title>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <Title level={3} className="sectionTitle text-left">OneFlo</Title>
                        <Title level={4} className="sectionSubTitle">Filter, Process, Re-engage</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>One click API to submit cases to all lenders</li>
                                <li>Complete tracking from lead to disbursals</li>
                                <li>Filter all leads and use lender specific BRE</li>
                                <li>Engagement engine for effective cross sell</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/oneflo'>Learn More</Link> 
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <img src={require('../../img/1flo1.png')} className="pdImg" alt='oneflo'/>
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}} className="order-xs-2">
                        <img src={require('../../img/svitch.png')} className="pdImg" alt='svitch'/>
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}} className="content-pd-lft">
                        <Title level={3} className="sectionTitle text-left">Svitch</Title>
                        <Title level={4} className="sectionSubTitle">Svitch to the financial stack</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>Make vendor & employee payouts over Cards</li>
                                <li>Sustain cashflow, end usage control on spends</li>
                                <li>Plan, execute, monitor & control all expenses</li>
                                <li>Exciting rewards & loyalty points for everyone</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/svitch'>Learn More</Link> 
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center" >
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <Title level={3} className="sectionTitle text-left">Finety</Title>
                        <Title level={4} className="sectionSubTitle">Let us scale together</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>Re-imagine distribution now through collaboration</li>
                                <li>Deliver solution and  decision along user journey</li>
                                <li>New value source for customer, revenue for you</li>
                                <li>Integrate journey workflows for your customers</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/finety'>Learn More</Link> 
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <img src={require('../../img/finety-b.png')} className="pdImg" alt='finety'/>
                    </Col>
                </Row>
            </div>
        </Content>
    </Layout>
    <Layout className='white'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center">Solutions</Title>
                <Tabs centered  className='centeredTabs' defaultActiveKey="1"
                    items={[
                    {
                        label: `Integrate`,
                        key: 'tab1',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Automation solution</li>
                                                <li>Embedded solution</li>
                                                <li>Access gateway</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Many systems, many silos and many frustrated customers. 
                                        Keep the customer journey intact while delivering financial products along the way.
                                        </div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/integrate.png')} className="pdImg" alt="integrate"/>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `Decide`,
                        key: 'tab2',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Decisioning engines</li>
                                                <li>Business rule engines</li>
                                                <li>Value added services</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Decisions are driven by data and its analysis, 
                                        in real time with the right context. Now, customize decision through right data and tools.
                                        </div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/decide.png')} className="pdImg" alt="decide"/> 
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `Reach`,
                        key: 'tab3',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Add channel partners</li>
                                                <li>Co-lending solutions</li>
                                                <li>New product creation</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Networks are a thing of beauty and their effects transform 
                                        businesses. Join the network to deliver new values to your customers through easy integrations.</div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/reach.png')} className="pdImg" alt="reach"/>
                                    </Col>
                                </Row>  
                            </>
                        ),
                    },

                    {
                        label: `Manage`,
                        key: 'tab4',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Lead management</li>
                                                <li>Channel management</li>
                                                <li>Operational efficiency</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Customer acquisition cost and Lifetime value decide the 
                                        success trajectory of businesses. Deliver best customer experience at the most effective cost.
                                        </div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/manage.png')} className="pdImg"y alt="manage"/> 
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    ]}
                >
                </Tabs>
            </div>
        </Content>
    </Layout>

    {/* <Layout className='white cust-str'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center">Customer Stories</Title>
                <Row justify="space-around" gutter={[30]}>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/black.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/black.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                </Row>
            </div>
        </Content>
    </Layout> */}
    <CallToAction></CallToAction>
    <Foot/>
    </>
  )
}
