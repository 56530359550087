import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Row, Col, Collapse} from 'antd';
const { Content } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;

export default function Terms() {
  return (
    <>
      <Head></Head>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                {/* <Col xs={{span:24}} md={{span:24}} lg={{span:24}}>
                  <Title level={3} className="pageTitle">TERMS OF USE (FOR CUSTOMERS)</Title>
                  <p>This document is a computer-generated electronic record published in terms of Rule 3 of the Information Technology (Intermediaries Guidelines) Rules, 2011 read with Information Technology Act, 2000 and does not require any physical or digital signature.</p> 
                  <p>This document applies to the access and use of the website (www.OneInfinity.in), software, mobile app, technology platform, telecom network and any such facilitation resources as may be offered (“OneInfinity Platform”). The OneInfinity Platform is owned by One Arc Labs   Private Limited, a company incorporated under the Companies Act, 2013 and having its registered office at 120, Damji Shamji Udyog Bhavan, Veera Desai Road, Andheri West, Mumbai 400053 (”OneInfinity”).</p>
                  <p>The OneInfinity Platform acts as in intermediary marketplace platform which offers and sells, various financial products such as credit cards, personal loans, SME loans, home loans, LAP, insurance products, etc and any such products, built and/or offered by partner banks and institutions/NBFCs, (“Financial Products”) to Platform users from time to time.</p>
                  <p>These Platform related terms (“Terms”) are applicable to you (hereinafter referred to as “You” or “Your” or “Yourself”) when You use or access the OneInfinity Platform or any part thereof in anyway, including, without limitation, using any information, content, services, etc., available therein, and You agree to and be bound by them. If You do not agree or accept any of the Terms herein, please do not access/use the OneInfinity Platform in any way whatsoever.</p>
                  <p>In order to access/use the OneInfinity Platform and to avail the Financial Products therefrom, You are required to first complete the registration process as provided on the OneInfinity Platform.</p>
                  <p>BY CLICKING THE ACCEPTANCE BUTTON OR BY ACCESSING, USING OR INSTALLING ANY PART OF THE ONEINFINITY PLATFORM, YOU, EXPRESSLY AGREE TO AND FULLY CONSENT TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ANY OF THE TERMS HEREOF, PLEASE DO NOT ACCESS/USE ANY PART OF THE ONEINFINITY PLATFORM.</p>
                  <p>You acknowledge that You have fully read as well as fully understood all the terms of these Terms and fully agree to be bound by them as well as the additional terms, if any, including but not limited to Our Privacy Policy, accessible at the link [https://www.OneInfinity.in/privacy-policy], which is deemed incorporated in these Terms by reference and which forms an integral part thereof.</p>
                  <p><b>GENERAL</b></p>
                  <p>1.1 By accessing the OneInfinity Platform and by creating an account with OneInfinity, You are accepting these Terms and You represent and warrant that You have all the right, authority, and capacity to enter into these Terms.</p>
                  <p>1.2 In order to use the OneInfinity Platform and avail the Financial Products and other services as available therefrom, You must register with OneInfinity by creating Your personal account on the Platform by providing certain personal and non-personal information about Yourself as prompted by the OneInfinity Platform’s registration form. Apart from taking personal information that directly identifies You. You represent and warrant that: </p>
                  <p>(a) all required registration as well as personal information You submit shall always be true and accurate; and </p>
                  <p>(b) You will maintain the accuracy of such information; else be liable for any risks and consequences arising therefrom. Without limiting the generality of the foregoing, You agree to provide OneInfinity with any identification documents which OneInfinity might request from You from time to time for the purposes of verifying Your identity. If You provide any information that is untrue, inaccurate, not updated or incomplete (or becomes untrue, inaccurate, not updated or incomplete), or OneInfinity has reasonable grounds to suspect that such information is untrue, inaccurate, not updated or incomplete, OneInfinity shall have the right to suspend or terminate Your account and/or refuse any and all current or future use of the OneInfinity Platform (or any part thereof).</p>
                  <p>1.3 You may delete Your account at any time, for any reason, by emailing OneInfinity at info@OneInfinity.in. You are solely responsible for maintaining the confidentiality of Your OneInfinity Platform account login information and are fully responsible for any and all activities that take place under Your said account. You agree to immediately notify OneInfinity of any unauthorized use or suspected unauthorized use or disclosure of Your said account or any other breach of security thereof. We cannot and will not be held liable for any loss or damage arising from Your failure to comply with the above requirements and for any unauthorized use or disclosure of Your account details.</p>
                  <p>1.4 You shall create only one account with OneInfinity and shall not directly or indirectly create more than one account.
                  </p><p>1.5 You further represent and warrant that You are of at least 18 years of age in order to be competent to contract and enter into these Terms and abide by them.
                  </p><p>1.6 Subject to the terms of these Terms, OneInfinity grants You a non-transferable and non-exclusive, license to use the OneInfinity  Platform only for Your personal and non-commercial use and for the purposes as envisaged under these Terms.
                  </p><p>1.7 The rights granted to You in these Terms are subject to the following restrictions: 
                  </p><p>(a) You shall not license, sell, rent, lease, transfer, assign, distribute, customize, or otherwise commercially exploit the OneInfinity  Platform, or otherwise host or sub-license the same or any part thereof; 
                  </p><p>(b) You shall not modify, make derivative works of, adapt, translate, disassemble, reverse engineer or decompile any part of the OneInfinity Platform, or otherwise attempt to discover the source code therein or any part thereof; 
                  </p><p>(c) You shall not access the OneInfinity Platform in order to build or promote a similar or competitive website/platform; 
                  </p><p>(d) You shall not infringe or violate the Intellectual Property Rights (as defined later) in the OneInfinity  Platform in any way whatsoever; and 
                  </p><p>(e) You shall not copy, reproduce, distribute, republish, download, display, post or transmit the OneInfinity Platform or any part thereof in any form or by any means.
                  </p><p>1.8 Any future release, update or other addition to functionality of the OneInfinity Platform shall be at OneInfinity’s sole discretion and shall always be subject to the terms of these Terms and as may be amended from time to time. You agree that OneInfinity will not be liable to You or to any third party for any such future release, update, or other addition to functionality or for any modification, suspension or discontinuance of the OneInfinity Platform or any part thereof.
                  </p><p>1.9 All Intellectual Property Rights and other proprietary rights in and to the OneInfinity Platform vests with OneInfinity and/or its licensors. The provision of the OneInfinity Platform does not transfer to You or to any third party, any rights, title and/or interest in or to such Intellectual Property Rights.
                  </p><p>1.10 The OneInfinity Platform may use third party vendors and service providers and their payments gateways for accepting payments therefrom on the OneInfinity Platform. You hereby authorize OneInfinity and the said third party service providers to run all accepted payment authorizations provided by You, to store your payment card and banking and other financial details and to charge Your payment card/account for the services fees and any other amounts owed under these Terms. To the extent permitted by applicable law and subject to OneInfinity’s Privacy Policy accessible at the link [https://www.OneInfinity.in/privacy-policy], You acknowledge and agree that OneInfinity may use the said third party vendors and service providers to process payments and manage Your payment method information. By providing the payment method information, You represent, warrant and covenant that You: 
                  </p><p>(a) are legally authorized to provide such information; and 
                  </p><p>(b) legally authorized to make payments using the payment methods available on the OneInfinity Platform. When You authorize a payment using any acceptable payment method via the OneInfinity Platform, You further represent and warrant that there are or will be sufficient funds or credit available to complete the payment using the designated payment method. To the extent that any amounts owed under these Terms cannot be collected from Your payment method(s), You will be solely responsible for paying such amounts by other means.
                  </p><p>1.11 By making use of the OneInfinity Platform, and furnishing Your personal details, You hereby agree that You are interested in knowing more or availing and/or purchasing various Financial Products and availing various services, offers, campaigns or other promotional material that OneInfinity or any other third party may offer/provide/share/send You from time to time through any means, including but not limited to telephone, SMS, e-mail, Whatsapp or any other messaging service/mobile application or any other physical, electronic or digital means/mode . You hereby agree that OneInfinity may contact You either electronically or through phone, to understand Your interest in such selected products and services and to fulfil Your requirements or complete Your application. Further, You also expressly agree and authorize OneInfinity and its partners, service providers, advisors, vendors and other third parties to contact You for the purpose of offering or inviting Your interest in availing various other products/services offered by third parties, or for sending other marketing campaigns, offers, or any other information either on the OneInfinity Platform or through various communication modes. You agree and consent to receive communications relating to all of the foregoing and expressly waive any registration or preference made under DND/NCPR list under the applicable TRAI regulations in force.
                  </p><p>1.12 You agree and acknowledge that for undertaking any financial transaction through the OneInfinity Platform, OneInfinity may undertake OneInfinity Platform’s client/customer/user’s due diligence measures and seek mandatory information required for Know-Your-Customer (“KYC”) purpose which, as a customer, You are obliged to give, while facilitating Your request for availing any of the Financial Products in accordance with applicable law and regulations. OneInfinity may obtain sufficient information to establish, to its satisfaction or its partner banks/financial institutions/NBFCs, the identity of each client/customer/user, and to ascertain the purpose of the intended nature of the relationship between You and them. You agree and acknowledge that OneInfinity can undertake enhanced due diligence measures (including any documentation), to satisfy itself relating to such due diligence requirements in line with the requirements and obligations under applicable laws and regulations.
                  </p><p>1.13 You agree and authorise OneInfinity to share Your personal information with the credit bureau or banks, financial institutions or NBFCs for the purpose of assessment of Your eligibility for the Financial Products and services offered, directly or indirectly, on the OneInfinity Platform.
                  </p><p>The usage of the OneInfinity Platform may also require You to provide consent for keying in your personal information (including but not limited to any personal data or sensitive personal data as defined under applicable law) or to authorize OneInfinity to derive Your data/information from any source or public registry, as may be necessary to complete Your profile or Your KYC application on the OneInfinity Platform, conduct due diligence on You, assess Your eligibility for the Financial Products and services, undertaking KYC checks and/or to process Your application through the OneInfinity Platform. Your personal information may also be used or shared with third parties, including but not limited to OneInfinity’s vendors, service providers, analytics and research partners in India and abroad, with the sole objective of making Your experience on the OneInfinity Platform better, faster, friction-less and paperless to the extent possible. However, OneInfinity shall adhere to best industry practices, including information security, data protection and privacy law while processing such applications. However, OneInfinity shall not be liable to You against any liability or claims which may arise out of such transactions as any such personal information is being collected, used, processed and shared with Your consent as agreed under OneInfinity’s Privacy Policy at the link https://www.OneInfinity.in/privacy-policy
                  </p><p>1.14 You shall not sell or license the OneInfinity Platform or any content/information or software associated with or derived from it or use any bot, robot, spider, other automatic device, or manual process to monitor or copy the OneInfinity Platform and any of its content or interfere or disrupt the functionality of the OneInfinity Platform; or take any action that imposes an unreasonably or disproportionately large load on OneInfinity’s infrastructure/network or use any device, or interfere or attempt to interfere with the OneInfinity Platform or forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through the OneInfinity Platform.
                  </p><p>1.15 You may only use the OneInfinity Platform to search for various Financial Products and related service as may be displayed on it from time to time and You will not use the OneInfinity Platform to make a fraudulent application for any Financial Product listed on the OneInfinity Platform. You agree not to use the OneInfinity Platform for any purpose that is unlawful, illegal or forbidden by these Terms, or any applicable laws. OneInfinity may, at its sole discretion, at any time and without advance notice or liability, suspend, terminate or restrict Your access to all or any component of the OneInfinity Platform.
                  </p><p>1.16 You are prohibited from posting or transmitting to or through this OneInfinity Platform: 
                  </p><p>(i) any unlawful, threatening, libellous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law; 
                  </p><p>(ii) any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services); and 
                  </p><p>(iii) any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary rights of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from Your posting of content on the OneInfinity Platform.

                  </p><p><b>PROPRIETARY RIGHTS</b>

                  </p><p>2.1 “Intellectual Property Rights” shall means all copyrights, trademarks, patents and other intellectual property rights recognized by the laws of any country, whether registered or not.
                  </p><p>2.2 OneInfinity and its licensors retain all right, title, and interest in and to all Intellectual Property Rights related in and to the OneInfinity Platform. The OneInfinity logos and names are OneInfinity’s trademarks. Nothing in these Terms grants You a right to use any of OneInfinity’s trademarks. All other product names, company names, trademarks, logos, and symbols on the OneInfinity Platform may be the trademarks of their respective owners.
                  </p><p>2.3 Nothing in these Terms grants You any kind of license or confers any right to download, copy, create derivative works, modify, reverse engineer, reverse assemble, decompile or otherwise attempt to discover any source code, or sell, assign, sub-license, grant a security interest in or otherwise transfer any right in the OneInfinity Platform. Further, You have no right whatsoever to remove, obscure, or alter any proprietary rights notices (including trademark and copyright notices), which may be affixed to or contained within the OneInfinity Platform.
                  </p><p>2.4 You further acknowledge that the OneInfinity Platform may contain certain information which is proprietary and/or designated confidential by OneInfinity and that You shall not disclose or share such information with any third party without OneInfinity’s prior written consent.


                  </p><p><b>THIRD PARTY LINKS/OFFERS</b>

                  </p><p>The OneInfinity Platform may provide links to third party websites, products, services and resources (“Third Party Data”). Since OneInfinity has no control over such Third Party Date, You acknowledge and agree that OneInfinity is not responsible or liable for any such Third Party Data, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such Third Party Data. OneInfinity provides Third Party Data only as a convenience to You and does not review, approve, monitor, endorse, warrant or make any representations with respect to the same. Your interaction with any third party relying on such Third Party Data accessed through the OneInfinity Platform is at Your own risk, and OneInfinity will have no liability with respect to the acts, omissions, errors, representations, warranties, breaches or negligence of any such third parties or for any personal injuries, death, property damage, or other damages or expenses resulting from Your interactions with them. You should make all prior investigation You feel appropriate before proceeding with any transaction in connection with such Third Party Data.


                  </p><p><b>OUR PARTNERS</b>
                  </p><p>4.1 Display of various Financial Products and services offered by third parties on the OneInfinity Platform does not in any way imply, suggest, or constitute any sponsorship, endorsement, recommendation, opinion, advice or approval of OneInfinity in favour/against such third parties or their products/services. You agree that OneInfinity is in no way responsible for the accuracy, timeliness or completeness of information it may obtain from these third parties. OneInfinity is only performing the role of an intermediary/ marketplace/ facilitator to enable distribution of such products and services offered by OneInfinity’s partners (lenders, credit card companies, mutual fund companies) to You. Your interaction with any third party accessed through the OneInfinity Platform is at Your own risk, and OneInfinity will have no liability with respect to the acts, omissions, errors, representations, warranties, breaches or negligence of any such third parties or for any personal injuries, death, property damage, or other damages or expenses resulting from Your interactions with them. You should make all prior investigation You feel appropriate before proceeding with any transaction in connection with such third parties.
                  </p><p>4.2 You agree and acknowledge that the conferment of any Financial Product to You shall be at the sole discretion of OneInfinity’s aforesaid partners while making any application through the OneInfinity Platform ; OneInfinity shall not be held liable for any delay, rejection or approval of any application made through the OneInfinity Platform.


                  </p><p><b>DISCLAIMER</b>

                  </p><p>5.1 We make no warranties regarding the OneInfinity Platform and/or any content and products and services made available therefrom and each of the foregoing is provided on an “as is” and “as available” basis. OneInfinity expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, security and accuracy, as well as all warranties arising by usage of trade, course of dealing, or course of performance. OneInfinity makes no warranty, and expressly disclaims any obligation, that: (a) the content of the OneInfinity Platform will be up-to-date, complete, comprehensive, accurate or applicable to Your circumstances; 
                  </p><p>(b) the OneInfinity Platform will meet Your requirements or will be available on an uninterrupted, timely, secure, or error-free basis; 
                  </p><p>(c) the results/outcome that may be obtained from the use of the OneInfinity Platform will be accurate or reliable; or 
                  </p><p>(d) the quality of any products, services, information, or other material obtained by You through the OneInfinity Platform will meet Your expectations.
                  </p><p>5.2 Some of the content on the OneInfinity Platform is aimed at spreading general awareness about various financial products and ancillary services but does not constitute any investment advice, and the same should not be construed in any way whatsoever as any solicitation, endorsement, advertising, marketing or promotion of such products and services by OneInfinity. These contents and/or information are derived from publicly available sources and OneInfinity cannot verify or confirm the genuineness, authenticity or veracity of such content and information. You should make all prior investigation You feel appropriate before proceeding to avail any such products and services.
                  </p><p>5.3 Notwithstanding anything contained in this Clause 5, the OneInfinity Platform reserves the right to conduct additional verification for security or other reasons if it is not satisfied with the creditability of Your transaction.
                  </p><p>5.4 All payments made on or through the OneInfinity Platform are managed by third party vendors and service providers. Use of the payment facilities provided by them through the OneInfinity Platform shall not render the OneInfinity Platform liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards any of the products or services listed and made available on and through the OneInfinity Platform. The OneInfinity Platform shall not be responsible for any damages, interests or claims arising from not processing of Your transaction. To clarify, OneInfinity will not be liable to You if You are unable to complete a transaction as a result of any limit by applicable law or fault of such third party vendors/service providers or the banl/ financial institution/ NBFCs, or if they fail to honor any credit or debit to or from an account associated with such payment method.
                  </p><p>5.5 You hereby agree to provide accurate information, such as credit/debit information for purchasing any Financial Product or availing any service on or through the OneInfinity Platform. You further warrant that You shall not use payment information or instrument that is not lawfully owned by You. In addition to these Terms, the terms and conditions of the bank/ financial institution/ NBFC shall also be applicable to You. OneInfinity disclaims any liability arising out of declining of payment by such bank/ financial institution/ NBFCs.
                  </p><p>5.6 No deliveries of any of the Financial Products or services shall be made outside the territorial boundaries of India.
                  </p><p>5.7 Your interactions with any Platform users are solely between You and such user and We will not be responsible for any loss or damage incurred as a result of any such interactions. In case of any dispute between You and any such user, We are under no obligation to be involved with the same; however, We may use commercially reasonable efforts to try to resolve any such disputes.


                  </p><p><b>LIMITATION OF LIABILITY</b>

                  </p><p>In no event shall OneInfinity (including its officers, directors, employees, representatives, affiliates, successors, assigns, vendors and service providers) will be responsible or liable to You or any third party for 
                  </p><p>(a) any injury, death, loss, claim, act of god, accident, delay, or 
                  </p><p>(b) lost profit or any indirect, special, exemplary, punitive, incidental or consequential damages of any kind, whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with 
                  </p><p>(i) these Terms or any failure or delay, including without limitation, the use of or inability to use the OneInfinity Platform or any part thereof or availing of any products/services therefrom, or 
                  </p><p>(ii) any use of the OneInfinity Platform or any of its content, or 
                  </p><p>(iii) any interactions or transactions of You with, or act or omission of You in relation to any Platform users, or 
                  </p><p>(iv) the performance or non-performance by OneInfinity or any of its vendors/service provider, even if OneInfinity has been advised of the possibility of damages to such parties or any other party, or 
                  </p><p>(v) any damage to or viruses that may infect your device.


                  </p><p><b>INDEMNITY</b>

                  </p><p>You agree to indemnify and hold OneInfinity (and its officers, directors, agents, vendors, service providers and employees) (“Indemnified Parties“) harmless from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature, including reasonable attorneys’ fees, made by any third party against the Indemnified Parties or imposed against the Indemnified Parties or arising out of or related to 
                  </p><p>(i) Your breach of any of these Terms the Privacy Policy and/or any of OneInfinity’s other policies, or 
                  </p><p>(ii) Your violation of any applicable law, rules or regulations, or 
                  </p><p>(iii) Your violation of the rights of a third party, or 
                  </p><p>(iv) Your unauthorized, improper, illegal or wrongful use of the OneInfinity Platform, or 
                  </p><p>(v) Your interaction with any other Platform user. This indemnification obligation will survive the expiry or termination of these Terms.


                  </p><p><b>TERM AND TERMINATION</b>

                  </p><p>These Terms will remain in full force and effect while You use the OneInfinity Platform and have an account with OneInfinity. OneInfinity may 
                  </p><p>(a) suspend Your right to use the OneInfinity Platform, whether in part or in full, or 
                  </p><p>(b) terminate these Terms, at any time for any reason if You violate any provisions of these Terms. Upon termination of these Terms, Your OneInfinity Platform account and right to access and use the OneInfinity Platform will terminate immediately. OneInfinity reserves its right to continue to use Your content/information to the extent permitted under applicable law and as stated in OneInfinity’s Privacy Policy available at the link [https://www.OneInfinity.in/privacy-policy]. OneInfinity will not have any liability whatsoever to You for any such termination.


                  </p><p><b>WAIVER</b>

                  </p><p>Any failure or delay by OneInfinity to enforce or exercise any provision of these Terms, or any related right, shall not constitute a waiver by OneInfinity of that provision or right. The exercise of one or more of OneInfinity’s rights hereunder shall not be a waiver of, or preclude the exercise of, any rights or remedies available to OneInfinity under these Terms or in law or at equity. Any waiver of any provision shall only be effective if made in writing and executed by a duly authorized officer of OneInfinity.


                  </p><p><b>FORCE MAJEURE</b>
                  </p><p>If the performance of the  OneInfinity Platform is prevented, restricted, hindered, delayed or interfered for any reason not in the reasonable control of OneInfinity, including but not limited to labour disputes, strikes, acts of God, floods, lightning, severe weather, shortages of materials, rationing, inducement of any virus, malware, trojan or other disruptive mechanisms, any event of hacking or illegal usage of the OneInfinity Platform, utility or communication failures, earthquakes, war, revolution, acts of terrorism, civil commotion, acts of public enemies, blockade, embargo, epidemic, pandemic or any law, order, proclamation, regulation, ordinance, demand or requirement having legal effect of any government or any judicial authority or representative of any such government, or any other act whatsoever, whether similar or dissimilar to those referred to in this Clause 10, then OneInfinity shall be excused and discharged from such performance to the extent of and during the period of such force majeure event, and such non-performance shall, in no manner whosoever, amount to a breach by OneInfinity of its obligations herein or incur any legal liability on OneInfinity.

                  </p><p><b>ASSIGNMENT</b>

                  </p><p>You shall not assign or otherwise transfer any of Your rights or obligations under these Terms. OneInfinity shall have the right to assign its rights and duties under these Terms without without any notice to You


                  </p><p><b>GOVERNING LAW  AND JURISDICTION</b>
                  </p><p>The terms of this Agreement shall be subject to and governed by the laws of India and the courts at Mumbai, India shall have exclusive jurisdiction over all matters arising out of or relating to this Agreement.


                  </p><p><b>AMENDMENTS</b>
                  </p><p>These Terms is subject to occasional revision, and if OneInfinity makes any substantial changes, OneInfinity may (or may not) notify You by sending You an e-mail to the last e-mail address You provided to OneInfinity (if any) and/or by prominently posting notice of the changes on the OneInfinity Platform. Any changes to these Terms will be effective immediately upon such notification/posting. Continued use of the OneInfinity Platform following notice of such changes shall indicate Your express acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Upon any change, OneInfinity will notify the updated Terms on the OneInfinity Platform or by other means.


                  </p><p><b>GRIEVANCE OFFICER</b>

                  </p><p>In accordance with Information Technology Act, 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                  </p><p>Name: <br />
                  Address: <br />
                  Email: info@OneInfinity.in <br />
                  Phone No.: 022- (between 10 AM to 6 PM on weekdays)


                  </p><p><b>CONTACT US</b>

                  </p><p>Please contact Us by email on info@OneInfinity.in for any questions or comments regarding these Terms or pertaining to the OneInfinity Platform. 

                  </p><p>Last Updated on: August 29th, 2022.</p>
                </Col> */}
                <Col xs={{span:24}} md={{span:24}} lg={{span:24}}>
                  <Title level={3} className="pageTitle">Terms & Condition</Title>
                  <p className='text-justify'>PLEASE READ THESE TERMS AND CONDITIONS ("TERMS") CAREFULLY. THESE TERMS FORM A LEGAL AGREEMENT BETWEEN ONE ARC LABS PRIVATE LTD AND YOU. 
                    BY CLICKING ON "I ACCEPT" OR "I AGREE" OR BY DOWNLOADING, INSTALLING OR OTHERWISE USING THE SERVICES, YOU ACCEPT AND AGREE TO THE TERMS CONTAINED HEREIN. 
                    IF YOU DO NOT AGREE TO THESE TERMS, DO NOT CLICK "I ACCEPT" OR "I AGREE" OR DOWNLOAD, INSTALL OR USE THE SERVICES.</p>
                  <Collapse accordion>
                    <Panel header="Definitions" key="1">
                    <div class="privacy-content">
                      <p>"Application or app." shall mean oneinfinity, the mobile application created, developed and designed by the company For providing the services.</p>
                      <p>"Lender" shall mean non-banking financial company or banks with whom the company has tied up for loan sanction, Which would sanction, process and grant the loan to the customer(s), through the platform.</p>
                      <p>"Company" or "We" shall mean one arc labs private limited, a company incorporated under the companies act, 2013</p>
                      <p>"Customer(s)" or "You" or "End-users" shall mean any person who accesses, downloads, uses, views the platform and The services.</p>
                      <p>"Loan" shall mean the loan that you may apply for through the platform and which is sanctioned and granted by Lender, subject to the applicable terms and conditions of the loan agreement.</p>
                      <p>"Loan agreement" shall mean the loan agreement to be executed between lender and the customer(s) for granting the Loan whether in physical or electronic form as may be applicable from time-to-time.</p>
                      <p>"Online stores" shall mean windows store, android google play, ios app store or any other online store or portal Where the app will be made available by the company to the end-users, from time to time.</p>
                      <p>"Outstanding amount(s)" shall mean the loan, interests and charges due and payable by you to lender, on respective Due date(s).</p>
                      <p>"Platform" shall mean the app and the website collectively.</p>
                      <p>"Services" shall mean the services of granting, sanctioning, lending of short term loans, through the platform by Lender.</p>
                      <p>"Third party platforms" shall mean social networking platforms, such as facebook, linkedin and other similar Platforms.</p>
                      <p>"User data" shall mean any data, information, documents or materials submitted with the company prior to or during The use of the services.</p>
                      <p>"Website" shall mean www.Oneinfinity.In, managed and operated by the company for the provision of services.</p>
                    </div>
                    </Panel>
                    <Panel header="Services" key="2">  
                    <div class="privacy-content">      
                      <p>
                          Through the services, you may apply for the loan, subject to the fulfilment of the eligibility criteria laid down in
                          The mobile app. You understand that the company has been appointed by lender to collect, authenticate, track your
                          Location, verify and confirm the user data, documents and details as may be required by lender to sanction the loan.
                          Lender authorizes the company to collect and store the user data through the internet / website / mobile application
                          Form ("Mobile application form") available on the platform. In order to avail the services, you are required to
                          Register with the company by logging in through your third party platforms ("User account"). During the application
                          Process, you shall be required to share and upload the user data on the mobile application form. User data shall
                          Include personal information including but not limited to your name, e-mail address, gender, date of birth, mobile
                          Number, passwords, photograph, mobile phone information including contact numbers, sms and browsing history, data
                          And login-in credentials of third party platforms, financial information such as bank documents, salary slips, bank
                          Statements, pan card, bank account no., data from credit information companies, data required for know your customer
                          Compliances, requirement and other relevant details ("Personal information"). You agree that the personal
                          Information shall always be accurate, correct and complete. As part of the services, you authorize us to import your
                          Details and personal information dispersed over third party platforms. You understand and acknowledge that we may
                          Periodically request for updates on such personal information and we may receive such updated information from third
                          Party platforms.
                      </p>
                      
                      <p>
                          All transactions undertaken on your behalf by the company will be on the basis of your express instructions/consent
                          And will be strictly on a non-discretionary basis. You also authorise the company to get your credit information
                          Report from one or more credit information companies as decided by the company from time to time. Once you verify
                          And upload the user data and/or other documents and details in the mobile application form, the company shall
                          Process the same. Upon the completion of the document verification by the company, the loan may be sanctioned by
                          Lender to you, subject to fitting eligibility criteria and other conditions set forth by lender for sanctioning the
                          Loan. Thereafter, you are required to fill and upload the ecs/nach mandate form/cheque or any other document as may
                          Be required by lender . The company may collect the physical documents including signatures on those documents
                          Required for sanctioning and processing the loan. Upon the collection of documents by the company, lender shall
                          Disburse the loan subject to the terms and conditions of the loan agreement.
                      </p>
                      
                      <p>
                          The sanctioned loan shall be disbursed as per the mode provided in the mobile application form. You are required to
                          Repay the outstanding amount(s) to lender, on the respective due date(s) mentioned in the mobile application form.
                      </p>
                      
                      <p>
                          You understand and acknowledge that the company reserves the right to track your location ("Track") during the
                          Provision of services, and also in the event that you stop, cease, discontinue to use or avail the services, through
                          Deletion or uninstallation of mobile app or otherwise, till the event that your obligations to pay the outstanding
                          Amount(s) to lender exist. Deletion, uninstallation, discontinuation of our services, shall not release you from the
                          Responsibility, obligation and liability to repay the outstanding amount(s).
                      </p>
                      
                      <p>
                          You understand and acknowledge that you shall be solely responsible for all the activities that occur under your
                          User account while availing the services. You undertake that the company shall not be responsible and liable for any
                          Claims, damages, disputes arising out of use or misuse of the services. By usage of the services, you shall be
                          Solely responsible for maintaining the confidentiality of the user account and for all other related activities
                          Under your user account. The company reserves the right to accept or reject your registration for the services
                          Without obligation of explanation.
                      </p>
                      
                      <p>
                          You understand and acknowledge that, you are solely responsible for the capability of the electronic devices and the
                          Internet connection, you chose to run the platform. The platformâ€™s operation or the services on your electronic
                          Device is subject to availability of hardware, software specifications, internet connection and other features and
                          Specifications, required from time to time.
                      </p>
                      
                      <p>
                          The user data provided during the registration is stored by the company for your convenience. You are not required
                          To log-in to your user account, every time, to use or access the platform. You understand and acknowledge that by
                          Accepting these terms, you authorize us to track, fetch and use the user data, including but not limited to your
                          Personal information, for the purpose of authentication and any updates with regards to your credentials.
                      </p>
                    </div>
                    </Panel>
                    <Panel header="License" key="3">
                      <div class="privacy-content">
                        <p>
                          - License to use the app:in order to use the services, you are required to download and install the app. For
                          This purpose, you represent and warrant that you are of the age of majority as per the applicable laws to
                          Which you are subject to and are competent to understand, enter into, and comply with these terms. The
                          Company grants you a limited, non-exclusive, non-transferable, non-sub-licensable and revocable right to
                          Download, install and use the app. The app. Is licensed and not sold to you and shall only be used as per
                          These terms.
                        </p>
                        <p>
                          - Scope of license:you may install, download, access or use the app through the online stores on/from mobile
                          Phones, tablets or any other electronic devices.
                        </p>
                        <p>
                            - Maintenance &amp; support: you acknowledge that while the company may, at its sole discretion, provide
                            Maintenance and support for the app from time to time, the company shall have no specific obligation
                            Whatsoever to furnish such services to you.
                        </p>
                
                        <p>
                            - Updates/upgrades: we may launch new updates/upgrades for the app., you may subscribe to the same through the
                            Online stores. In the event, you choose not to update/upgrade the app, certain features or functionality
                            Shall not be accessible to you.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Restrictions" key="4">
                      <div class="privacy-content">
                        <p>You agree not to:</p>
                        <p>
                            - Use the platform or the services for committing fraud, embezzlement, money laundering, or for any unlawful
                            And/or illegal purposes;
                        </p>
                        <p>
                            - To reproduce, duplicate, copy, sell, resell or exploit any portion of the app;
                        </p>
                        <p>
                            - Upload, post, email, transmit or otherwise make available any content that is unlawful, harmful,
                            Threatening, abusive, harassing, torturous, defamatory, vulgar, obscene, libelous, invasive of anotherâ€™s
                            Privacy, hateful, or racially, ethnically or otherwise objectionable through the platform;
                        </p>
                
                        <p>- Use the platform to harm or injure any third party;</p>
                        <p>- Impersonate any person or entity, on the platform;</p>
                        <p>- Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted
                            Through the app;</p>
                        <p>- Upload, post, email, transmit or otherwise make available any content that you do not have a right to make
                            Available under any law or under contractual or fiduciary relationships (such as inside information,
                            Proprietary and confidential information learned or disclosed as part of employment relationships or under
                            Nondisclosure agreements);</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any content that infringes any
                            Patent, trademark, trade secret, copyright or other proprietary rights of any party;</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any unsolicited or unauthorized
                            Advertising, promotional materials, "Junk mail," "Spam", "Chain letters," "Pyramid schemes," or any other
                            Form of solicitation;</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any material that contains
                            Software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                            Functionality of any computer software or hardware or telecommunications equipment;</p>
                        <p>- Disrupt the normal flow of or otherwise act in a manner that negatively affects other userâ€™s ability to
                            Engage in real time exchanges;</p>
                        <p>- Interfere with or disrupt the platform or servers or networks connected to the platform, or disobey any
                            Requirements, procedures, policies or regulations of networks connected to the platform;</p>
                        <p>- Intentionally or unintentionally violate any applicable local, state, national or international laws and any
                            Regulations having the force of law.</p>
                      </div>
                    </Panel>
                    <Panel header="Content Available" key="5">
                      <div class="privacy-content">
                        <p>You acknowledge that the company makes no representations or warranties about the material, data, and information,
                      Such as data files, text, facts and figures, computer software, code, audio files or other sounds, photographs,
                      Videos, or other images (collectively, the "Content") which you may have access to as part of the services, or
                      Through your use of the platform. Under no circumstances, shall the company be liable in any way for any content,
                      Including, but not limited to any infringing content, any errors or omissions in content, or for any loss or damage
                      Of any kind incurred as a result of the use of any content posted, transmitted, linked from, or otherwise accessible
                      Through or made available via the platform. The content on the platform should not be regarded as an offer,
                      Solicitation, invitation, advice or recommendation to buy or sell investments, securities or any other instrument or
                      Financial products / schemes of the company (including its affiliates), unless expressly covered in these terms.</p>
                      </div>
                    </Panel>
                    <Panel header="Proprietary Rights of The Company" key="6">
                      <div class="privacy-content">
                        <p>You understand, acknowledge and agree that the company is the sole owner of all rights, title and interest,
                        Including any and all intellectual property rights in the content, platform, services, logos, trade names, brand
                        Names, designs and any necessary software used in connection with the platform.
                        </p>
                        <p>
                        There may be proprietary logos, service marks and trademarks found on the platform whether owned/used by the company
                        Or otherwise. By displaying them on the platform, the company is not granting you any license to utilize the
                        Proprietary logos, service marks, or trademarks. Any unauthorized use of the same may violate applicable
                        Intellectual property laws.
                        </p>
                        <p>
                        You understand and acknowledge that the platform is owned by the company. Nothing under these terms shall be deemed
                        To be a transfer in ownership, rights, title, from the company to you or any third party, in the platform. You are
                        Entitled to avail the services offered by the company during the validity of your registration with the company.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Links to Third Party Sites" key="7">
                      <div class="privacy-content">
                      <p>The platform may contain links to other websites owned and operated by third parties who are not related to the
                      Platform ("Linked websites"). The linked websites are not under the control of the company and the company shall not
                      Be responsible for the content of any linked websites or any hyperlink contained in a linked website and makes no
                      Representation or warranty with respect to the content of any such third party sites.
                      </p>
                      <p>
                      The platform provides these links to you as a convenience only and the inclusion of any link does not imply any
                      Endorsement of the linked website by the company. Your access or use of such linked website is entirely at your own
                      Risk. The company shall not be a party to any transaction between you and the linked website. Your use of a linked
                      Website is subject to these terms and conditions of that respective linked website.
                      </p>
                      <p>
                      The platform may also contain third party advertisements, if any. The display of such advertisements does not in any
                      Way imply an endorsement or recommendation by/of the relevant advertiser, its products or services. You shall
                      Independently refer to the relevant advertiser for all information regarding the advertisement and its products
                      And/or services. The company accepts no responsibility for any interaction between you and the relevant third party
                      And is released from any liability arising out of or in any way connected with such interaction.
                      </p>
                      </div>
                    </Panel>
                    <Panel header="Ancillary Services" key="8">
                      <div class="privacy-content">
                      <p>You may get access to chat rooms, blogs, feedbacks, reviews and other features ("Ancillary services") that are/may
                      Be offered from time to time on the platform and may be operated by us or by a third party on our behalf. You shall
                      Not (nor cause any third party to) use these ancillary services to perform any illegal activities (including without
                      Limitation defaming, abusing, harassing, stalking, threatening, promoting racism, or otherwise violating the legal
                      Rights, such as rights of privacy, of others) or immoral activities, falsely stating or otherwise misrepresenting
                      Your affiliation with a person or entity. Additionally, the platform may contain advice/opinions and statements of
                      Various professionals/ experts/ analysts, etc. The company does not endorse the accuracy, reliability of any such
                      Advices/opinions/ and statements. You may rely on these, at your sole risk and cost. You shall be responsible for
                      Independently verifying and evaluating the accuracy, completeness, reliability and usefulness of any opinions,
                      Services, statements or other information provided on the platform. All information or details provided on the
                      Platform shall not be interpreted or relied upon as legal, accounting, tax, financial, investment or other
                      Professional advice, or as advice on specific facts or matters. The company may, at its discretion, update, edit,
                      Alter and/or remove any information in whole or in part that may be available on the platform and shall not be
                      Responsible or liable for any subsequent action or claim, resulting in any loss, damage and or liability. Nothing
                      Contained herein is to be construed as a recommendation to use any product or process, and the company makes no
                      Representation or warranty, express or implied that, the use thereof will not infringe any patent, or otherwise.
                      </p>
                      </div>
                    </Panel>
                    <Panel header="Termination" key="9">
                      <div class="privacy-content">
                        <p>The company reserves its rights to terminate these terms in the event:</p>
                              <p>- You breach any provision of these terms;</p>
                              <p>- The company is required to do so under law; or</p>
                              <p>- The company chooses to discontinue the services being offered or discontinue to operate the platform;</p>
                              <p>- The license granted to use the app expires;</p>
                              <p>- Of non-payment of outstanding amount(s).</p>
                              <p>
                              Upon termination of these terms, the rights and licenses granted to you under these terms shall cease to exist, and
                              You must forthwith stop using the platform and the services and repay the outstanding amount(s). Notwithstanding
                              Anything contained in these terms or otherwise, the termination of these terms for any reason whatsoever, shall not
                              Affect your obligations, including but not limited to repayment of the outstanding amount(s).</p>
                      </div>
                    </Panel>
                    <Panel header="Disclaimer of Warranties" key="10">
                    <div class="privacy-content">
                      <p>You expressly understand and agree that</p>
                      <p>
                      Your use of the services and the platform is at your sole risk. The services and the platform are provided on an "As
                      Is" and "As available" basis. The company expressly disclaims all warranties of any kind, whether express or
                      Implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose
                      And non-infringement. Any material downloaded or otherwise obtained through the access or use of the platform, is at
                      Your own discretion and risk and that you will be solely responsible for any damage to your computer system,
                      Electronic data or loss of data that results from the download of any such material. No advice or information,
                      Whether verbal or written, obtained by you from the company, for the services or through the platform shall create
                      Any warranty not expressly stated in these terms. The services are intended for personal, non-commercial use. You
                      Shall be solely responsible for the use, misuse, improper usage of the services and the platform. The company shall
                      Not be liable for any damages accruing out of the use of the services which have not been expressly stipulated under
                      These terms. The company makes no warranty, including implied warranty, and expressly disclaims any obligation,
                      That: (a) the contents are and will be complete, exhaustive, accurate or suitable to your requirements; (b) the
                      Platform or the services will meet your requirements or will be available on an uninterrupted, timely, secure, or
                      Error-free basis; (c) the results that may be obtained from the use of the platform or services will be accurate or
                      Reliable.</p>
                    </div>
                    </Panel>
                    <Panel header="Indemnity" key="11">
                      <div class="privacy-content">
                        <p>You agree to indemnify and hold the company, and its subsidiaries, affiliates, officers, agents, co-branders or
                            Other partners, and employees, harmless from any claim or demand, including attorneysâ€™ fees, made by any third party
                            Due to or arising out of (I) your violation of these terms; (ii) your violation of any rights of other users of the
                            Platform; (iii) your use or misuse of the platform or the services; (iv) your violation of applicable laws.</p>
                      </div>
                    </Panel>
                    <Panel header="Limitations of Liability" key="12">
                      <div class="privacy-content">
                        <p>You expressly understand and agree that the company, including its directors, officers, employees, representatives
                        Or the service provider, shall not be liable for any direct, indirect, incidental, special, consequential or
                        Exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other
                        Intangible losses (even if the company has been advised of the possibility of such damages), resulting from; (a) use
                        Or the inability to avail the services (b) inability to use the platform (c) failure or delay in providing the
                        Services or access to the platform (d) any performance or non-performance by the company (e) any damages to or
                        Viruses that may infect your electronic devices or other property as the result of your access to the platform or
                        Your downloading of any content from the platform and (f) server failure or otherwise or in any way relating to the
                        Services.</p>
                      </div>
                    </Panel>
                    <Panel header="Force Majeure" key="13">
                      <div class="privacy-content">
                      <p>Without limiting the foregoing, under no circumstances shall the company be held liable for any damage, loss, loss
                      Of services of platform, due to deficiency in provision of the services resulting directly or indirectly from acts
                      Of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures,
                      Computer equipment failures, telecommunication equipment failures, change in applicable regulations, including
                      Reserve bank of india regulations, or any other government regulations, floods, storms, electrical failure, civil
                      Disturbances, riots.</p>
                      </div>
                    </Panel>
                    <Panel header="Privacy Policy" key="14">
                      <div class="privacy-content">
                        <p>The personal information collected/shared/uploaded for the provision of services has been exhaustively covered in Our privacy policy ("Privacy policy"). Our privacy policy is available at here.</p>
                      </div>
                    </Panel>
                    <Panel header="Change" key="15">
                      <div class="privacy-content">
                        <p>The company reserves the right to modify, change, substitute, remove, suspend or update these terms or any
                          Information thereof at any time by posting the updated terms on the platform. Such changes shall be effective
                          Immediately upon such posting. Continued use of the services or the platform, subsequent to making the changes,
                          Shall be deemed to be your acceptance of the revised terms.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Choice of Law and Jurisdiction" key="16">
                      <div class="privacy-content">
                        <p>This agreement shall be construed and governed by the laws of india without regard to principles of conflict of
                          Laws. Parties further agree that the courts in mumbai, maharashtra, india shall have an exclusive jurisdiction over
                          Such disputes.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Miscellaneous" key="17">
                      <div class="privacy-content">
                        <p>Entire understanding: these terms, along with the loan agreement, mobile application form and privacy policy,
                            Constitute the entire understanding between you and the company with relation to the services. Waiver:the failure of
                        The company to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right
                        Or provision. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising
                        Out of or related to availing of the services or these terms must be filed within one (1) year after such claim or
                        Cause of action arose or be forever barred. Severability: if any provision of these terms is found by a court of
                        Competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect
                        To the partiesâ€™ intentions as reflected in the provision, and the other provisions of these terms shall remain in
                        Full force and effect.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Violations" key="18">
                      <div class="privacy-content">
                        <p>Please report any violations or grievances with relation to these terms to the company at info@oneinfinity.In</p>
                      </div>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Foot />
    </>
  )
}
