import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';

const { Content } = Layout;
const { Title } = Typography;

export default function About() {

  return (
    <>
    <Head />
    <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                    <Col xs={{span:24}} md={{span:24}} lg={{span: 18}}>
                      <Title level={4} className="text-left aboutUS">About Us</Title>
                      <Title level={2} className="sectionTitle text-left" style={{margin:'0px'}}>We’re empowering employees anywhere to make better financial decisions.</Title>
                    </Col>  
                    <Col xs={{span:24}} md={{span:24}} lg={{span: 6}}></Col>                  
                </Row>
            </div>
        </Content>
    </Layout>
    <Layout className='white'>
        <Content>
            <div className="site-layout-content">
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}></Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <Title level={3} className="sectionTitle text-left">Our Mission</Title>
                        <div className='sectionpdDesc'>
                          <p>We're here to empower employees anywhere to make better financial decisions.</p>
                          <p>Innovative companies today are at the cutting edge of how business gets done. Modern businesses are empowering their teams, automating workflows, digitizing information, and operating globally. 
                            They’re doing things differently, inventing new paths, breaking the old way.</p>
                          <p>Everything about companies has changed, but banks have not. The big businesses of the future are acting more like startups, and banks can't build what they need.</p>
                          <p>To empower their employees to make better financial decisions that drive the business forward, these companies need products that enable their new ways of thinking and keep them growing for the long haul.</p>
                          <p>So we’re building a first-of-its-kind solution to integrate the financial services and software they’ll need along their way. While they build the future, we’ll be building everything they need to launch confidently, scale smarter, and reach their full potential.</p>
                        </div> 
                    </Col>
                    
                </Row>
            </div>
        </Content>
    </Layout>
    <Foot />
    </>
  )
}
