import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;

export default function Complementor() {
  return (
    <>
    <Head />
      <Layout className='white'>
        <Content className='pagePadding'>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 11}}>
                  <Title level={2} className="sectionTitle text-left">Streamline financial solution delivery with customer journey</Title>
                  <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 13}}>
                  <img src={require('../../img/complementor-cover.png')} alt="complementor" width='100%' />
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center d-none-xs">Focus on growth, use the network</Title>
              <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Focus on growth</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/embed-sol1.png')} style={{width:'100%'}} className="br12 mb-20" alt='Embedded-Solutions'/>
                    <Title level={4} className="sectionTitle text-left">Embedded Solutions</Title>
                    <p>Offer products along the user journey on your platform including- credit, investment, card rails & insurance.</p>
                </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/bpa.png')} style={{width:'100%'}} className="br12 mb-20" alt="BPA"/>
                    <Title level={4} className="sectionTitle text-left">Business Process Automation</Title>
                    <p>Help your customers to create automated workflows that do multiple things and make their work life simple.</p>
                </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                    <img src={require('../../img/partner-network.png')} style={{width:'100%'}} className="br12 mb-20" alt="Partner-Network"/>
                    <Title level={4} className="sectionTitle text-left">Partner Network</Title>
                    <p>Provide offerings from the partner network and offer your products through the network to potential customers.</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Got online presence, OI network is for you</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className="text-center">
                  <img src={require('../../img/complementor-loop.png')} className="loopImg" alt='complementor-loop'/>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      {/* <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 6}}>
                  <Title level={4}>Case Study</Title>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 18}} className='d-flex justify-space-between'>
                  <div className='case_study'>
                    <Title level={5}>Project Year</Title>
                    <span>2022</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Bank Type</Title>
                    <span>Digital Greenfield</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Cloud Partner</Title>
                    <span>OpenShift and Google Cloud</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Geography</Title>
                    <span>India</span>
                  </div>
                </Col>
              </Row>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mt-50 align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/dummy.jpg')} style={{width:'100%'}} alt=""/>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <Title level={4} className="sectionTitle text-left">Challenges</Title>
                  <p style={{marginBottom:'50px'}}>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>

                  <Title level={4} className="sectionTitle text-left">The Solution</Title>
                  <p>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout> */}
    <Foot />
    </>
  )
}
