import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import CallToAction from '../CallToAction';

const { Content } = Layout;
const { Title } = Typography;


export default function OneFlo() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                    <Title level={2} className="sectionTitle text-left">OneFlo</Title>
                    <div className='Titledescription'>Filter, Process, Engage, Re-Engage</div>
                    <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/1flo1.png')} alt="1flo" width='100%' />
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
                <div className='d-flex flex-xs-column ant-row'>
                  <div className='productsPNL'>
                    <img src={require('../../img/cvp.png')} alt='cvp'/>
                    <Title level={4} className="sectionTitle text-left">More value from customers</Title>
                    <p>Identify needs of customers better & match them with right products, earning more while delivering great value.</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/inbuild-int.png')} alt='inbuild-int'/>
                    <Title level={4} className="sectionTitle text-left">Inbuild integrations</Title>
                    <p>OneFlo is pre-integrated with 100s of systems. Push leads to Banks, NBFCs, Insurers and track them now.</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/decision-making.png')} alt='decision-making'/>
                    <Title level={4} className="sectionTitle text-left">Decision making at click</Title>
                    <p>Recommendations for when and what products to sell, and the supplier to be matched with is now at one click.</p>
                  </div>
                </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">How it works</Title>
              <div className='d-flex flex-xs-column ant-row'>
                <div className='productsPNL'>
                  <img src={require('../../img/signUp.png')} alt='sign-up'/>
                  <Title level={4} className="sectionTitle text-left">Sign up</Title>
                  <p>Sign up for the program, and customize your plan</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/setup.png')} alt='setup'/>
                  <Title level={4} className="sectionTitle text-left">Setup</Title>
                  <p>Get your team on interfaces branded for you</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/scaleUp.png')} alt='scaleUp'/>
                  <Title level={4} className="sectionTitle text-left">Scale</Title>
                  <p>Start using the features and see the difference</p>
                </div>
              </div>
            </div>
        </Content>
      </Layout>
      <div className='callToAction'>
        <CallToAction />
      </div>
      <Foot />
    </>
  )
}
