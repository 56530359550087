import React from 'react';
import './App.css';
import { Routes, Route} from "react-router-dom";
import Home from './components/Home/Home';
import Oneflo from './components/Pages/Oneflo';
import Svitch from './components/Pages/Svicth';
import Finety from './components/Pages/Finety';
import Demand from './components/Pages/Demand';
import Distributor from './components/Pages/Distributor';
import Complementor from './components/Pages/Complementor';
import Solutions from './components/Pages/Solutions';
import Supply from './components/Pages/Supply';
import About from './components/Pages/About';
import Contact from './components/Pages/Contact';
import Privacy from './components/Pages/Privacy';
import Terms from './components/Pages/Terms';
import Calculator from './components/Pages/Calculator';
import GoToTop from './GoToTop';
import Help from './components/Pages/Help';
import { ParallaxProvider } from 'react-scroll-parallax';
import Integrate from './components/Pages/Integrate';
import Decide from './components/Pages/Decide';
import Reach from './components/Pages/Reach';
import Manage from './components/Pages/Manage';

function App() {
   
  return (
    <>
    <ParallaxProvider>
    <GoToTop>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="oneflo" element={<Oneflo/>} />
      <Route path="svitch" element={<Svitch/>} />
      <Route path="finety" element={<Finety/>} />
      <Route path="integrate/0" element={<Integrate/>} />
      <Route path="integrate/1" element={<Integrate/>} />
      <Route path="integrate/2" element={<Integrate/>} />
      <Route path="decide/0" element={<Decide/>} />
      <Route path="decide/1" element={<Decide/>} />
      <Route path="decide/2" element={<Decide/>} />
      <Route path="reach/0" element={<Reach/>} />
      <Route path="reach/1" element={<Reach/>} />
      <Route path="reach/2" element={<Reach/>} />
      <Route path="manage/0" element={<Manage/>} />
      <Route path="manage/1" element={<Manage/>} />
      <Route path="manage/2" element={<Manage/>} />
      <Route path="demand" element={<Demand/>} />
      <Route path="distributor" element={<Distributor/>}/>
      <Route path="complementor" element={<Complementor/>} />
      <Route path="solutions" element={<Solutions/>} />
      <Route path="supply" element={<Supply/>} />
      <Route path="about-us" element={<About/>} />
      <Route path="contact" element={<Contact/>} />
      <Route path="privacy-policy" element={<Privacy/>} />
      <Route path="terms-conditions" element={<Terms/>} />
      <Route path="help" element={<Help/>} />
      <Route path="calculator" element={<Calculator/>} />
    </Routes>
    </GoToTop>
    </ParallaxProvider>
    </>
  );
}

export default App;
