import React, { useState } from "react";
import {useInterval} from 'react-use';

export default function Slider() {
  
  const firstArray = ["All"]
  const secondArray = ["Finance"]
  const thirdArray = ["Cards"]
  const fourthArray = ["Partners"]
  const [items, setItems] = useState(firstArray)
  const [count, setCount] = useState(0)
  const [delay, setDelay] = useState(5000);

  useInterval(
      () => {
          //animate react
          if(count === 0){
              setCount(1)
              setItems(secondArray)
              //console.log('1', delay)
          }
          else if(count === 1){
              setCount(2)
              setItems(thirdArray)
              //console.log('2', delay)
          }
          else if(count === 2){
              setCount(3)
              setItems(fourthArray)
              //console.log('3', delay)
          }
          else if(count === 3){
              setCount(0)
              setItems(firstArray)
              //console.log('3', delay)
          }
      }, delay )

  return (
    <>        
        {items.map((item, index)=>(
            <span className='animated fadeInUp' key={index}> {item} </span>
        ))}
      
    </>
  );
}
